<template>
	<div :class="themeClass" class="contBox margin-t-10 bg-fff" v-loading="pageLoading">
		<div class="newPersonbtn-title font-size18 font-weight700 margin-t-4 padding-b-13 font-color-000">我的地址</div>
		<div class="newPersonbtn-Box">
			<div class="text-center font-size16 margin-b-25">{{pageTitle}}</div>
			<el-form :model="addressForm" :rules="rules" ref="addressForm" label-width="140px" class="demo-ruleForm">
				<el-form-item label="收货人" prop="fLinkman">
					<el-input v-model="addressForm.fLinkman" placeholder="请输入收货人"></el-input>
				</el-form-item>
				<el-form-item label="手机号码" prop="fLinkPhone">
					<el-input v-model="addressForm.fLinkPhone" 
						maxlength="11" 
						placeholder="请输入手机号码" 
						onkeyup="this.value=this.value.replace(/[^\d]/g,'')">
					</el-input>
				</el-form-item>
				<el-form-item label="行政区域" class="areaBox" prop="area">
					<el-col :span="24">
						<selectArea v-if="pageTitle == '修改地址信息' && addressForm.area.length>0" v-model="addressForm.area"></selectArea>
						<selectArea v-if="pageTitle == '新增地址信息'" v-model="addressForm.area"></selectArea>
					</el-col>
					<!-- <div class="mapBtn pointer font-size14 font-color-666" @click.once="getMyAddress">
						<span class="iconfont icon-dingwei margin-r-6"></span>获取当前地址
					</div> -->
				</el-form-item>
				<el-form-item label="详细地址" prop="fAddress">
					<el-input v-model="addressForm.fAddress" placeholder="请输入详细地址"></el-input>
				</el-form-item>
				<el-form-item label="工程名称" prop="fProjectName" v-if="addTypeId == 1">
					<el-input v-model="addressForm.fProjectName" placeholder="请输入工程名称"></el-input>
				</el-form-item>
				<el-form-item label="电子邮箱" prop="feMail" v-if="addressList == 1">
					<el-input v-model="addressForm.feMail" placeholder="请输入电子邮箱"></el-input>
				</el-form-item>
				<!-- <el-form-item label="工程名称" prop="feMail" v-if="addTypeId == 1">
					<el-input v-model="addressForm.feMail" placeholder="请输入工程名称"></el-input>
				</el-form-item> -->
				<el-form-item label="地址类型" prop="fAddressType">
					<!-- <el-select v-model="addressForm.fAddressType" clearable placeholder="请选择地址类型">
						<el-option v-for="item in addressList" :key="item.fAddressTypeID" :label="item.fAddressType" :value="item.fAddressTypeID">
						</el-option>
					</el-select> -->
					<el-input v-model="addressForm.fAddressType" disabled></el-input>
				</el-form-item>
				<el-form-item label="默认地址" prop="fIsDefault">
					<el-switch v-model="addressForm.fIsDefault"></el-switch>
				</el-form-item>
				<el-form-item>
					<div id="container" style="height: 200px;" v-show="mapShow"></div>
				</el-form-item>
				<el-form-item>
					<div class="comBtn font-color-fff bg-theme text-center border-theme radius2" @click="submitForm('addressForm')">保存</div>
				</el-form-item>
			</el-form>
			<div class="bg-box"></div><!-- 背景图 -->
		</div>
	</div>
</template>
<script>
	import {
		mapGetters
	} from 'vuex';
	import selectArea from '@/components/selectArea/selectArea.vue'
	export default {
		name: "SelectArea",
		props: {
			value: Array
		},
		data() {
			return {
				pageLoading: false,
				areaList: [],
				mapShow: false, //地图
				pageTitle: "",
				//area: "", //区域id
				addr: '新疆乌鲁木齐市新市区经济开发区卫星路499号秦郡大厦B座501室',
				fTransportToolID: '', //编辑车辆的车辆id
				carToolList: [],
				//地图初始化
				maplat: '',
				maplng: '',
				city: '',
				//end
				addressList: '', //是否需要填写邮箱号
				addressForm: {
					fLinkman: '',
					fLinkPhone: '',
					area: [],
					fAddress: '',
					fProjectName: '',
					fAddressType: '',
					feMail: '',
					fIsDefault: false,

				},
				rules: {
					fLinkman: [{
							required: true,
							message: '请输入收货人',
							trigger: 'blur'
						},
						/* {
							min: 2,
							max: 20,
							message: '长度在 2 到 20 个字符',
							trigger: 'blur'
						} */
					],
					area: [{
						required: true,
						message: '请选择行政区域',
						trigger: ['blur', 'change']
					}],
					fLinkPhone: [{
							required: true,
							message: '请输入正确的手机号码',
							trigger: 'change'
						},
						{
							validator: (rule, value, callback) => {
								if (value === '') {
									callback(new Error('请输入正确的手机号码'));
								} else {
									let phoneNumberRul = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
									if (phoneNumberRul.test(value)) {
										callback();
									} else {
										callback(new Error('请输入正确的手机号码'));
									}
								}
							},
							trigger: 'blur'
						}
					],
					fAddress: [{
						required: true,
						message: '请填写详细地址',
						trigger: 'blur'
					}],
					fProjectName: [{
						required: true,
						message: '请填写工程名称',
						trigger: 'blur'
					}],
					feMail: [{
							required: true,
							validator: (rule, value, callback) => {
								if (this.addTypeId == 1 || this.addTypeId == 3 && !value) {
									callback('请输入电子邮箱')
								} else {
									callback()
								}
							},
							trigger: 'blur'
						},
						{
							type: 'email',
							message: '请输入正确的电子邮箱地址',
							trigger: ['blur', 'change']
						}
					],
					fAddressType: [{
						required: true,
						message: '请选择地址类型',
						trigger: 'blur'
					}]
				},
				addTypeId: ""
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		components: {
			selectArea
		},
		mounted() {
			//console.log('this.area', this.area);
			/* 异步创建JS */
			var script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = 'https://map.qq.com/api/gljs?v=1.exp&key=PM5BZ-2GP6S-7GHO5-6SIIX-DZD2S-RUB7S&callback=init';
			document.body.appendChild(script);

			this.getAddressType(); //获取地址类型
			this.addTypeId = this.$route.query.addTypeId;
			//this.pageTitle = this.$route.query.pageType;
			//this.getTransportToolType();
			this.pageTitle = this.$route.query.pageType;
			if (this.pageTitle == "修改地址信息") {
				//console.log(this.$route.query.fAddressID+"地址id");
				this.editAddress();
			}
		},
		created() {

		},
		methods: {
			//查询地址类型
			getAddressType() {
				this.ApiRequestPost('/api/mall/ebbase/address-type/get-list', {}).then(
					result => {
						//console.log(result);
						result.obj.items.forEach((item) => {
							if (this.addTypeId == item.fAddressTypeID) {
								this.addressForm.fAddressType = item.fAddressType;
								this.addressList = item.fMustEmail;
							}
						});
					},
					rej => {}
				);
			},
			//初始化地图
			initMap() {
				//定义map变量，调用 TMap.Map() 构造函数创建地图
				// "lat": 43.794399第一个参数
				// "lng": 87.631676,第二个参数
				var center = new TMap.LatLng(this.maplat, this.maplng); //设置中心点坐标
				//初始化地图
				var map = new TMap.Map(container, {
					center: center
				});
				/* 这个就是那个地图上的指针点 */
				//创建并初始化MultiMarker
				var markerLayer = new TMap.MultiMarker({
					map: map, //指定地图容器
					//样式定义
					styles: {
						//创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
						myStyle: new TMap.MarkerStyle({
							width: 25, // 点标记样式宽度（像素）
							height: 35, // 点标记样式高度（像素）
							//src: '../img/marker.png', //自定义图片路径
							//焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
							anchor: {
								x: 16,
								y: 32
							}
						})
					},
					//点标记数据数组
					geometries: [{
						id: '1', //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
						styleId: 'myStyle', //指定样式id
						position: new TMap.LatLng(this.maplat, this.maplng), //点标记坐标位置
						properties: {
							//自定义属性
							title: 'marker1'
						}
					}]
				});

			},
			getMyAddress() {
				let _this = this;
				this.mapShow = true;

				this.$jsonp('https://apis.map.qq.com/ws/geocoder/v1/?address=' + this.addr +
						'&key=PM5BZ-2GP6S-7GHO5-6SIIX-DZD2S-RUB7S&output=jsonp', {})
					.then(json => {
						// alert(JSON.stringify(json.result.location));
						_this.maplat = json.result.location.lat;
						_this.maplng = json.result.location.lng;
						// 初始化地图,这里的1秒延迟是必要的,否则不能正常渲染出来 
						setTimeout(function() {
							_this.initMap()
						}, 1000);

					})
					.catch(err => {
						alert(JSON.stringify(err));
					});
			},
			editAddress() {
				this.ApiRequestPost('/api/mall/eb-customer/address/get', {
					"id": this.$route.query.fAddressID
				}).then(
					result => {
						this.addressForm = result.obj;
						this.addressForm.fIsDefault = this.addressForm.fIsDefault == 0 ? false : true;
						let pro = result.obj.fGovernmenAreaID.substring(0, 2);
						let cit = result.obj.fGovernmenAreaID.substring(0, 4);
						let vie = result.obj.fGovernmenAreaID.substring(0, 6);
						let are = result.obj.fGovernmenAreaID;
						this.addressForm.area = [pro, cit, vie, are];
						console.log(this.addressForm);
					},
					rej => {}
				);
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let areaN = this.addressForm.area;
						if (this.pageTitle == "修改地址信息") {
							this.pageLoading = true;
							this.addressForm.rowStatus = 16;
							this.addressForm.fIsDefault = this.addressForm.fIsDefault == true ? 1 : 0;
							this.addressForm.fGovernmenAreaID = areaN[areaN.length - 1];
							this.ApiRequestPut('/api/mall/eb-customer/address/update', this.addressForm).then(
								result => {
									this.pageLoading = false;
									this.$router.push({
										path: '/PersonalContent/MyAddress',
										query: '',
									});
								},
								rej => {
									this.pageLoading = false;
								}
							);
						} else if (this.pageTitle == "新增地址信息") {
							this.pageLoading = true;
							this.ApiRequestPost('/api/mall/eb-customer/address/create', {
								fAddressTypeID: this.addTypeId,
								fCustomerID: 0,
								//feMail: "",
								fGovernmenAreaID: areaN[areaN.length - 1],
								fAddress: this.addressForm.fAddress,
								feMail: this.addressForm.feMail, // 电子邮箱
								flng: 0,
								flat: 0,
								fLinkman: this.addressForm.fLinkman,
								fLinkPhone: this.addressForm.fLinkPhone,
								fRemark: "",
								fStatus: 1, //是否启用 默认启用
								fIsDefault: this.addressForm.fIsDefault == true ? 1 : 0, //是否默认0  this.addressForm.fIsDefault === 1 ? true : false
								fProjectName: this.addressForm.fProjectName,
								fLastUseTime: "",
								fCreatorUserID: 0,
								rowStatus: 4
								/* "fver": "" */

							}).then(
								result => {
									this.pageLoading = false;
									this.$router.push({
										path: '/PersonalContent/MyAddress',
										query: '',
									});

								},
								rej => {
									this.pageLoading = false;
								}
							);
						}

					} else {
						return false;
					}
				});
			}


		}
	}
</script>
<style lang="scss" scoped="scoped">
	.bg-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.contBox {
		width: 100%;
	}

	.demo-ruleForm {
		width: 480px;
		margin: 0px auto;
		position: relative;
		z-index: 1;
	}

	.newPersonbtn-Box {}

	.bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 25%;
	}

	.newPersonbtn-title {
		width: 73px;
		height: 22px;
		padding: 8px 14px;
	}

	.el-button {
		width: 160px;
		height: 40px;
	}

	::v-deep .el-input__inner,
	.el-select {
		width: 100%;
		height: 32px;
		line-height: 32px;
	}

	/* 按钮 */
	.comBtn {
		width: 160px;
		height: 38px;
		line-height: 38px;
		cursor: pointer;
		display: inline-block;
		margin-top: 20px;
	}

	.areaBox {
		position: relative;
	}

	.mapBtn {
		position: absolute;
		top: 0px;
		right: -122px;

		.icon-dingwei {
			font-size: 18px;
		}
	}

	#container {
		margin-left: -140px;
	}

	::v-deep .el-cascader {
		width: 100%;
	}

	::v-deep .el-cascader .el-input__inner {
		border: 1px solid #DCDFE6;
	}
</style>
